/**
 * +----------------------------------------------------------------------
 * | @Author: codeavatar   @Year: 2023
 * +----------------------------------------------------------------------
 * | @Email: codeavatar@aliyun.com
 * +----------------------------------------------------------------------
 **/

export default {

  //====================[信息稿件]====================

  /**
   * 发布信息
   */
  article_publish_list:'/info.Article/list',
  article_publish_save:'/info.Article/add',
  article_publish_read:'/info.Article/query',
  article_publish_update:'/info.Article/edit',
  article_publish_delete:'/info.Article/remove',
  article_publish_status:'/info.Article/status',
  article_publish_index:'/info.Article/index',

  //====================[基础权限]====================

  /**
   * 授权
   */
  auth_message:'/basic.Auth/message',
  auth_welcome:'/basic.Auth/welcome',
  auth_safe_api_path:'/codeavatar/basic.Auth/safeApiPath',
  auth_logout:'/basic.Auth/logout',
  auth_menus:'/basic.Auth/menus',
  auth_info:'/basic.Auth/info',
  plug_upload_file:'/basic.Upload/file',
  plug_upload_files:'/basic.Upload/files',
  plug_upload_download:'/basic.Upload/download',
  /**
   * 非授权
   */
  unauth_login:'/codeavatar/basic.Login/index',
  unauth_captcha:'/codeavatar/basic.Robot/code',
  unauth_sms:'/codeavatar/basic.Login/sms',
};