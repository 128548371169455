/**
 * +----------------------------------------------------------------------
 * | @Author: codeavatar   @Year：2024
 * +----------------------------------------------------------------------
 * | @Email: codeavatar@aliyun.com
 * +----------------------------------------------------------------------
 **/
import store from 'store';

const TASK_NAME = 'codeavatar.task.queue.2024';

/**
 * 任务队列
 * 因变量设置方法引用，固不能使用store存储，否则引用被过滤。
 */
export default {
    task_queue: {},
    //=================[核心方法]=================
    getTask(callback,key,interval,remark){
        return {callback:callback,key:key,time:interval,remark:remark,counter:interval};
    },
    runTask(task){
        if(Object.keys(this.task_queue).includes(task.key)){
            //执行操作
            if(this.task_queue[task.key].counter==1){
                new Promise(function (resolve,reject){
                    task.callback && task.callback();
                });
                console.log(task.remark+',回调事件已运行......');
                this.task_queue[task.key].counter = this.task_queue[task.key].time;
            }else {
                this.task_queue[task.key].counter--;
            }
            this.asyncData();
        }
    },
    addTask(task){
        if(Object.keys(this.task_queue).includes(task.key)) delete this.task_queue[task.key];
        this.task_queue[task.key] = task;
        this.asyncData();
    },
    addTasks(tasks){
        for (let task of tasks){
            this.addTasks(task);
        }
    },
    removeTask(key){
        if(Object.keys(this.task_queue).includes(key)) delete this.task_queue[key];
        this.asyncData();
    },
    removeTasks(keys){
        for (let key of keys){
            this.removeTask(key);
        }
    },
    initTask(){
      // this.task_queue = store.get(TASK_NAME);
    },
    clearTask(){
        // store.remove(TASK_NAME);
    },
    getQueue(){
        // let task = store.get(TASK_NAME);
        // if(task){ return Object.values(task) }
        if(this.task_queue){return Object.values(this.task_queue);}
        return [];
    },
    asyncData(){
        // store.set(TASK_NAME,this.task_queue);
    },
    //=================[核心方法]=================
}