/**
 * +----------------------------------------------------------------------
 * | @Author: codeavatar   @Year: 2023
 * +----------------------------------------------------------------------
 * | @Email: codeavatar@aliyun.com
 * +----------------------------------------------------------------------
 **/

import store from 'store';
import config from "@/codeavatar/config/config";

const UQ_PREFIX = config.dataModule+".";

export default {
    safe_api_path:'',
    permissions:[],
    //=================[核心方法]=================
    getSafeApiPath(){
      // return this.safe_api_path;
      return store.get(UQ_PREFIX+'safe_api_path');
    },
    setSafeApiPath(path){
      // this.safe_api_path = path;
        store.set(UQ_PREFIX+'safe_api_path',path);
    },
    getPermission(){
        this.permissions = store.get(UQ_PREFIX+'permissions');
        return this.permissions;
    },
    setPermission(permission){
        this.permissions = permission;
        store.set(UQ_PREFIX+'permissions',permission);
    },
    isPermission(permission){
        return this.permissions.includes(permission);
    },
    setAuthToken(token){
        store.set(UQ_PREFIX+'codeavatar_at',token);
    },
    getAuthToken(){
        return store.get(UQ_PREFIX+'codeavatar_at');
    },
    isAuthToken(){
        let _token = store.get(UQ_PREFIX+'codeavatar_at');
        return (_token != undefined && _token != "" && _token.length > 50);
    },
    clearAuthToken(){
        store.remove(UQ_PREFIX+'codeavatar_at');
    },
    //=================[基础方法]=================
    set(key,val){
      store.set(key,val);
    },
    get(key){
        return store.get(key);
    },
    remove(key){
        store.remove(key);
    },
    print(){
        store.each((val,key)=>{
            console.log(key,'=>',val);
        });
    },
    clear(){
        store.clearAll();
    }
}