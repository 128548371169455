<template>
  <router-view/>
</template>

<script>
import taskQueue from "@/codeavatar/data/task-queue";

export default {
  name: 'App',
  components: {},
  data(){
    return {
      browserWidth:100,
      browserHeight:100,
      //数据引擎方式
      driverType:'loop',//loop：轮循；websocket：socket；
      counterId:null,//轮循器标只
    };
  },
  created() {
    this.init();
  },
  mounted() {
    this.initCounter();
  },
  unmounted() {
    if(this.counterId) clearInterval(this.counterId);
  },
  methods:{
    /**
     * 初始化
     */
    init(){
      this.initEvent();
      this.$router.replace({name:'ZhengWu'});
    },
    initWH(){
      this.browserWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      this.browserHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    },
    initEvent(){
      window.onresize = ()=>{
        this.initWH();
        this.$codeavatar.log.info('window.onresize',[this.browserWidth,this.browserHeight]);
      };
      this.initWH();
    },
    initCounter(){
      console.log('轮循计时器，启动......');
      let _this = this;
      this.counterId = setInterval(()=>{
        console.log('轮循计时器，心跳......');
        _this.doTask();
      },1000);
    },
    /**
     * 操作
     */
    doTask(){
      let tasks = taskQueue.getQueue();
      for (let task of tasks){
        taskQueue.runTask(task);
      }
    }
  }
}
</script>

<style>
@import url('@/codeavatar/theme/default/codeavatar.css');
@import url('@/codeavatar/theme/default/print.css') print;

html{
  background:#2c3e50;
}
body{
  display: block; position: fixed;width: 100%; height: 100%;
  padding: 0px !important; margin: 0px !important;
  background: #fff;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%; height: 100%;
  padding: 0px !important; margin: 0px !important;
  color: #2c3e50; background: #efefef;
}
</style>
