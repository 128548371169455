/**
 * +----------------------------------------------------------------------
 * | @Author: codeavatar   @Year: 2023
 * +----------------------------------------------------------------------
 * | @Email: codeavatar@aliyun.com
 * +----------------------------------------------------------------------
 **/
/**
 * 【发布设置】
 */
// 环境模式（生产环境》production；开发环境》development；）
const isReleased = (process.env.NODE_ENV === 'production');
const isOrigin = true; //原始域名（前端与API为同源站点）
//终端信息
const appPlatform = 'user';
const appDevice = 'pc';
const appVersion = '1.0.0';
/**
 * 【API设置】
 */
//【数据模块】
const dataModule = 'user';
//【正式地址】
const NormalBaseUrl = 'http://www.lqxmt.cn';
const NormalWsUrl = 'ws://www.lqxmt.cn:9110';
const NormalSkey = 'MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCCH0R8cWVLnboFl9M9JEVcbOxUqSuthXYuHIniAe/sebW5n7DDkBopMA1WRvpeJZFIeg89lvaVS5QaHtEMpZeRGL5fWeWv1OSEogM8Vd5V5jrXRAUsSB8EOrE5//AFOzlDIbg4fA5q+HnbCe0UcGRoCNePpzzvq+1Y6KHqYYF+oFYcaEMT6xItqqWZt07P/NLe+5zP8AJ+gk34mNxRTMMELpCxFLE7kBpcJmK4Ib25qPD3zoZgsBUik3l9cm15Bh3W3eYPZuapvv5SE0A2UGJi6HI44JkFLkpcuDgAuK4VB+IEvQEd7+AmDz4x/A9chbzG+jS0N53B+QFbRQ+94h2HAgMBAAECggEAGR1B0abtPxf1NGkmSzG59WnMf1abaIVO1v2JG0b2cLTY/ze0HTqt4e81U8alQhCMJunwERoXA5+vuQ7jmEZJp6aX771hGHvFk1fVugp0vC2Qg8XMHczZt65AUIp2X/wbRDq43KYM/lZw5nbW/E/pAS9bs406tKOBTtyvi5ZAMRkosMmLLpqn2TOAmp3i1QfboByjd39jWsC58EsYT9ArC8R08yMPDClUq5IEgi5yeddu6rR6bGksRzpnM5+h4uZbLG7w2fbr8QGzTg7W32tspzt54sUgJ7abbTbYEmiQn3/L2QNBll1IWzaMqFJDm3oY+T4659d24pvmP7JUjK3rMQKBgQC4LgWPMV+wHm547KBiSvpHne/8GZXEfZTME7i5FFpJ6k3cSK7+PrEoJPxyLsQdadYoBF0lkoa9kS2lvXkaYJ12TzNMMwY8/UjFdWZ0zC/5kvk0Tax1Q5SIjDkiuqqjs9UGSHzui4FgVXCR81lxe65vg1rviKRuCVXwiRVFKYcG6QKBgQC03N/Ru+6lHQfhqzznKHGzwKAMDlAlY/RQWWOtY8FgbmzSxi74R+Nm7zN9ne3f78Wnp8hNDfZTkqOGwtnnWotNOphMpuQiuR5HRuKSAyAlFIA1rTZOhiTa7zFxAsSx1XY549CgNeHoA6TEbr6MMeIKGuXMm0PgUrgOYV0468ca7wKBgQCgrg3Nkd6QEMyCFLYLaqwUxNX0UwHVDVY+AEqctQPcDlxk/MgmKN13T2Up3GDd5FKgUMZOnBmO0lRHjxhTCcz1xTzUkoMzvCi7k6OR6/1Ct5VPqx/0ocO4qNTD1qQ98wq604yis/Rn7cIqomn61lMlGCzCYqxJDz35fi6fSw5w2QKBgBoGJhZxqjcL5FRuZUcFpMauktjpPlMC/OaK1jLexbZwbSwu5BdJkMkA5jHPBalRQOmM1N9xgN4pnWoIo34MozqrkbqF0RHF/TtYUcWcuYVcW85dbBEEcE0oWb7fHRpRkr9lrmE/H5BY5d516OkXTGUT1bkJJk0i2eUH0XgdRPr1AoGAGrFqs9I8GZTQX5sdSmzqoQZd3yOBC471Zdam00phktehOdFCOjceKAwQWvkUhMJhFQj8ljA9ossURYoPNoyIegzgq5CXSgUXn3xjDm/yLFdPAe7V+hfQ81/u4iIhafpdjyYUDDWXGUX/52doJJAOLztplz5A2ZbLEn4OZ8U7zgM==';
//【测试地址】
const TestBaseUrl = 'http://pro.softarticle.vip';
const TestWsUrl = 'ws://pro.softarticle.vip:9110';
const TestSkey = 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAgh9EfHFlS526BZfTPSRFXGzsVKkrrYV2LhyJ4gHv7Hm1uZ+ww5AaKTANVkb6XiWRSHoPPZb2lUuUGh7RDKWXkRi+X1nlr9TkhKIDPFXeVeY610QFLEgfBDqxOf/wBTs5QyG4OHwOavh52wntFHBkaAjXj6c876vtWOih6mGBfqBWHGhDE+sSLaqlmbdOz/zS3vucz/ACfoJN+JjcUUzDBC6QsRSxO5AaXCZiuCG9uajw986GYLAVIpN5fXJteQYd1t3mD2bmqb7+UhNANlBiYuhyOOCZBS5KXLg4ALiuFQfiBL0BHe/gJg8+MfwPXIW8xvo0tDedwfkBW0UPveIdhwIDAQAB,,';

let BASE_API_URL = (isReleased?NormalBaseUrl:TestBaseUrl);
let BASE_WS_URL = (isReleased?NormalWsUrl:TestWsUrl);
let BASE_SKEY = (isReleased?NormalSkey:TestSkey);

/**
 * 打包检测 (VUE3)
 */
if(process.env.NODE_ENV === 'production'){
    if(isOrigin){
        BASE_API_URL = `${location.origin}`;
        BASE_WS_URL = `ws://${location.origin}:9110`;
    }
}

export default {
    //核心控制
    released:isReleased,
    debug:!isReleased,
    baseurl:BASE_API_URL,
    basews:BASE_WS_URL,
    baseskey:BASE_SKEY,
    //测试
    test:{
        isOpen:false,
        catoken:'4c1df9784841d68a434a3c74550c0e9b3ab8b015e15026466a69da7dce3ba21760d5c2c3a98ceca9dbe9cf443cde8efe680280260e1c521d62f0808c9c7151bc87818783ed78546e57854d99fe776f966b4b7946bbc6c5316e75068584dd9fea6525030a7a4809e82917e6a54ae9108a60415bfab141dcc2ba0454e52a9e75c79d606722f236dbc7d1d35df28ef42de412482f53cdd4d66a18eb5452f53a9f76',
    },
    //固定参数
    getPageSize:()=>{
        return 10;
    },
    //应用信息
    app:{
        platform:appPlatform,
        device:appDevice,
        version:appVersion,
        dataModule,
    },
}