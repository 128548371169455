/**
 * +----------------------------------------------------------------------
 * | @Author: codeavatar   @Year: 2023
 * +----------------------------------------------------------------------
 * | @Email: codeavatar@aliyun.com
 * +----------------------------------------------------------------------
 **/
import axiosHttp from "@/codeavatar/tool/http/axios-http";
import logTool from "@/codeavatar/tool/log-tool";
import regexTool from "@/codeavatar/tool/regex-tool";
import data from '@/codeavatar/data/data';
import api from '@/codeavatar/config/api';
import {helper,browser} from "@/codeavatar/tool/helper";
import config from "@/codeavatar/config/config";
import uiData from "@/codeavatar/fk/ui/ui-data";

export default {
    // eslint-disable-next-line no-unused-vars
    install: (app, options) => {
        // 注入一个全局可用的 $codeavatar 方法
        app.config.globalProperties.$codeavatar = {
            //配置
            config:config,
            //工具
            help:helper,
            browser:browser,
            regex:regexTool,
            //框架
            uiData:uiData,
            //网络
            api:api,
            http:axiosHttp,
            //日志
            log:logTool,
            //存储
            storeData:data,
            //安全路径
            getApiRealPath(relative_api){
                if(relative_api.startsWith('/codeavatar')){
                    return '/'+config.app.dataModule+relative_api;
                }
                return '/'+config.app.dataModule+'/'+data.getSafeApiPath()+relative_api;
            },
            //打印
            getPrintConfig(config){
                let print = {
                    id:'table',
                    popTitle: '数据报表', // 打印配置页上方的标题
                    extraHead: '', // 最上方的头部文字，附加在head标签上的额外标签，使用逗号分割
                    preview: false, // 是否启动预览模式，默认是false
                    previewTitle: '报表打印预览', // 打印预览的标题
                    previewPrintBtnLabel: '开始打印', // 打印预览的标题下方的按钮文本，点击可进入打印
                    zIndex: 20002, // 预览窗口的z-index，默认是20002，最好比默认值更高
                    previewBeforeOpenCallback() { console.log('正在加载预览窗口！'); }, // 预览窗口打开之前的callback
                    previewOpenCallback() { console.log('已经加载完预览窗口，预览打开了！') }, // 预览窗口打开时的callback
                    beforeOpenCallback() { console.log('开始打印之前！') }, // 开始打印之前的callback
                    openCallback() { console.log('执行打印了！') }, // 调用打印时的callback
                    closeCallback() { console.log('关闭了打印工具！') }, // 关闭打印的callback(无法区分确认or取消)
                    clickMounted() { console.log('点击v-print绑定的按钮了！') },
                }
                if(config){
                    return Object.assign(print,config);
                }
                return print;
            },
        };
    }
}