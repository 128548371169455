/**
 * +----------------------------------------------------------------------
 * | @Author: codeavatar   @Year: 2023
 * +----------------------------------------------------------------------
 * | @Email: codeavatar@aliyun.com
 * +----------------------------------------------------------------------
 **/

import MainView from "@/views/MainView"

//error路由
import errorRouter from "@/codeavatar/router/error-router";


export default [
    {
        path:'/center',
        name: 'center',
        children:[
            {
                path:'zhengwu',
                name:'ZhengWu',
                component:()=>import('@/views/center/ZhengWuView'),
            },
            errorRouter,
        ],
        meta:{
            requiresAuth:false,
        },
    },
    {
        path:'/main',
        name:'Main',
        component:MainView,
    },
    {
        path:'/error',
        children: [
            {
                path: '403',
                name: '403',
                component:()=>import('@/views/basic/403'),
                meta:{
                    requiresAuth:false,
                },
            },
            {
                path: '404',
                name: '404',
                component:()=>import('@/views/basic/404'),
                meta:{
                    requiresAuth:false,
                },
            },
            {
                path: '500',
                name: '500',
                component:()=>import('@/views/basic/500'),
                meta:{
                    requiresAuth:false,
                },
            },
        ]
    }
];