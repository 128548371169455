<template>
  <Layout class="ca-layout">
    <Header class="ca-header">
      <Menu mode="horizontal" theme="primary" class="topbar" active-name="tab-home" @on-select="onTopBarSelect">
        <div class="nav-logo">
          <Image class="logo" :src="require('@/assets/logo_400X120.png')" :fit="'contain'">
            <template #error>
              <Icon type="ios-image-outline" size="24" color="#ccc" />
            </template>
          </Image>
        </div>
        <div class="nav-menu">

        </div>
        <div class="nav-other">
          <Icon v-if="isFullScreenEnabled" :type="isFullScreen?'md-contract':'md-expand'" size="24" @click="onFullscreen" class="menu-icon"/>
        </div>
      </Menu>
    </Header>
    <Layout class="ca-body">
      <Content class="ca-content">
        <el-scrollbar>
          <Row align="middle" :gutter="50">
            <Col :span="12">
              xxxxxxxxxxxxxxxxxxxxxxx
            </Col>
          </Row>
        </el-scrollbar>
      </Content>
    </Layout>
    <Footer class="ca-footer">版权 © {{appInfo.copyrightYear}} <a :href="appInfo.companySite" target="_blank">{{appInfo.companyName}}</a>  ●  技术支持：<a :href="appInfo.supportItSite" target="_blank">{{appInfo.supportItUnit}}</a> </Footer>
  </Layout>
</template>

<script>
import {
  Card, Col,
  Content,
  Dropdown, Footer,
  Header,
  Icon,
  Image,
  Layout, Link, List, ListItem,
  Menu, MenuGroup,
  MenuItem, Notification,
  Poptip, Row,
  Sider,
  Submenu,
} from "view-ui-plus";
import setting from "@/codeavatar/config/setting";

export default {
  name: "MainView",
  components: {
    Footer,
    Link,
    Col,
    Row,
    ListItem,
    List,
    Card,
    MenuGroup,
    Notification, Poptip, Dropdown, Submenu, Image, Icon, MenuItem, Menu, Sider, Content, Header, Layout},
  data(){
    return {
      appInfo:{},
      isFullScreenEnabled:false,
      isFullScreen:false,
      isSider:true,
      isCollapsed:false,
      siderActiveName:'',//'0-0',
      siderOpenNames:[],//['0'],
      menus:[],
      sider:[],
      userinfo:{},
      noticeList:[],
      messageList:[],
    }
  },
  methods:{
    /**
     * 初始化
     */
    initData(){

    },
    /**
     * 操作
     */
    doHomeView(){
      this.$router.replace({name:'home'});
    },
    /**
     * 事件
     */
    onFullscreen(){
      this.isFullScreen = !this.isFullScreen;
      if(this.isFullScreen){
        this.$codeavatar.help.openFullScreen();
      }else{
        this.$codeavatar.help.closeFullScreen();
      }
    },
  },
  created() {
    this.appInfo = setting.app;
    this.initData();
  },
  mounted() {
    this.isFullScreenEnabled = this.$codeavatar.help.isFullScreenSupport();
  },
  computed:{

  }
}
</script>

<style lang="scss" scoped >
/**基础样式**/
.ca-layout{
  position: relative; width: 100%; height: 100%;
  background-color: #e6a23c;
  .ca-header{
    padding: 0px; background-color: #FFFFFF;
    .topbar{
      height: 64px; line-height: 64px;
      display: flex; flex-direction: row; align-items: center; justify-content: space-between;
      .nav-logo{
        width: 200px; text-align: center;
        .logo{
          width: 150px;
        }
      }
      .nav-menu{
         flex: 1;
      }
      .nav-other{
        .avatar{
          width: 40px; height: 40px; border-radius: 50%;
        }
      }
    }
  }
  .ca-body{
    //height: calc(100vh - 60px);
    height: calc(100vh - 100px);
    background-color: #F5F5F5;
    .ca-sider{
      background-color: #FFFFFF;
    }
    .ca-sider-hidden{
      display: none;
    }
  }
  .ca-footer{
    background-color: #2d8cf0; border-top: 0.5px dotted #EFEFEF; color: #FFFFFF;
    height: 40px; padding: 0px; text-align: center; line-height: 40px;
    a{color: white;}
    a:hover{color: red;}
  }
}

/**其他样式**/
.service{
  li{
    list-style: none; line-height: 30px;
    display: flex; display: -webkit-flex;flex-direction: row; flex-wrap: wrap;
    span{
      display: inline-block; font-weight: bolder;
    }
    a{ margin-left: 5px; }
    a:hover{color: red;}
  }
}
</style>
<style lang="scss">
.ca-notification{
  .ivu-badge-count{
    top:8px !important;
  }
}

</style>