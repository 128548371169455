/**
 * +----------------------------------------------------------------------
 * | @Author: codeavatar   @Year：2023
 * +----------------------------------------------------------------------
 * | @Email: codeavatar@aliyun.com
 * +----------------------------------------------------------------------
 **/

//系统信息
const appInfo={
    appAllName:'大数据分板系统',
    appSimpleName:'智慧大数据',
    companyName:'邯郸市尚科网络科技有限公司',
    companySite:'#',
    copyrightYear:'2024',
    icpNo:'冀ICP备18027930号-2',//https://beian.miit.gov.cn/ 示例：冀ICP备00000000号
    //https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030702002388
    icpNo110:'',//https://beian.mps.gov.cn/#/query/webSearch 示例：冀公网安备00000000000000号
    supportItUnit:'尚科网络',
    supportItSite:'https://www.hdshangke.com/contact.html',
};

export default {
    app:appInfo,
};