/**
 * +----------------------------------------------------------------------
 * | @Author: codeavatar   @Year：2023
 * +----------------------------------------------------------------------
 * | @Email: codeavatar@aliyun.com
 * +----------------------------------------------------------------------
 **/
import logTool from "@/codeavatar/tool/log-tool";
import md5 from "md5";

//Md5签名
export const signMd5 = {
    makeSign: function (obj,keysecret) {
        if (!obj) {
            logTool.error('需要加密的数组对象为空',obj);
        }
        if (!keysecret) {
            logTool.error('密钥未获取',keysecret);
        }
        let plaintext = this.createLinkstr(obj) + '&codeavatar='+ keysecret;
        logTool.debug('待签名文本',plaintext);
        var encrypted = md5(plaintext);
        return encrypted;
    },
    createRndStr: function (len){
        let forNum = len/32+1;
        let tmpStr = '';
        for(let i=0; i<forNum; i++){
            tmpStr += md5(this.createTimeStamp()+Math.random());
        }
        return tmpStr.substring(0,len);
    },
    createRndNum:function(len){
        let tmpRndStr = Math.random().toString();
        let forNum = len / (tmpRndStr.length - 2) + 1;
        let tmpStr = '';
        for (let i = 0; i < forNum; i++) {
            tmpStr += Math.random().toString().substring(2);
        }
        return tmpStr.substring(0,len);
    },
    createTimeStamp: function (){
        //精确到秒（默认为毫秒）
        return Math.floor(new Date().getTime()/1000)+'';
    },
    createLinkstr:function (obj){
        var str = new Array(),tmpVal = null;
        //生成key升序数组
        var arrKeys = Object.keys(obj);
        arrKeys.sort();
        for (var key of arrKeys) {
            //过滤签名键
            if(key == 'caSign') continue;
            //格化值
            tmpVal = this.kvalFormat(obj[key]);
            if(tmpVal!==null) str.push(key + '='+tmpVal);
        }
        return str.join('&');
    },
    kvalFormat:function (val){
        if(val===''||val===null) return null;

        //js使用 typeof 全部类型：object、number、string、function、boolean、undefined、symbol
        //js使用 instanceof 全部类型：Array、Object、Undefined、Date、Number、String
        if(val instanceof Array){
            if(val.length>0) return JSON.stringify(val);
            return null;
        }
        if(typeof val == 'object'){
            return JSON.stringify(val);
        }
        if(['string','number','boolean'].includes(typeof val)) return val;

        return null;
    }
}
