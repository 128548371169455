import { createApp } from 'vue'
import App from './App.vue'
//插件
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import ViewUIPlus, {Message} from 'view-ui-plus'
import 'view-ui-plus/dist/styles/viewuiplus.css'
import print from 'vue3-print-nb'
//自定义
import router from "@/codeavatar/router/router";
import codeavatar from '@/codeavatar/plugs/codeavatar';
import auth from '@/codeavatar/data/data';
// 如果您正在使用CDN引入，请删除下面一行。
// import * as ElementPlusIconsVue from '@element-plus/icons-vue'

//路由守卫
//【前置守卫】
// eslint-disable-next-line no-unused-vars
router.beforeEach(async (to, from)=>{
    ViewUIPlus.LoadingBar.start();
    if(to.meta.requiresAuth){
        if(auth.isAuthToken()){
            let permission = auth.getPermission();
            if(!permission.includes(to.meta.authCode)){
                Message.error({
                    background: true,
                    content: '对不起，您无权操作！'
                });
                auth.clear();
                // router.push({path:'/',replace:true});
                // return {path:'/admin/login',replace:true};
                return {name:'login', replace:true};
            }
        }else{
            // router.push({path:'/',replace:true});
            // return {path:'/admin/login',replace:true};
            return {name:'login', replace:true};
        }
    }
    return true;
});
//【后置守卫】
// eslint-disable-next-line no-unused-vars
router.afterEach(route => {
    ViewUIPlus.LoadingBar.finish();
});
//应用
const app = createApp(App);
// for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
//     app.component(key, component)
// }
app.use(ElementPlus)
    .use(ViewUIPlus)
    .use(router)
    .use(print)
    .use(codeavatar)
    .mount('#app');
